import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoAdd, LazySvgoAddFile, LazySvgoArrowDown, LazySvgoArrowLeft, LazySvgoArrowRight, LazySvgoArrowUp, LazySvgoBag, LazySvgoBaselinePlus, LazySvgoCart, LazySvgoChat, LazySvgoCheck, LazySvgoCheckCircle, LazySvgoCheckMark, LazySvgoChevronDown, LazySvgoChevronLeft, LazySvgoChevronLeftThin, LazySvgoChevronLeftThinS, LazySvgoChevronNext, LazySvgoChevronPrev, LazySvgoChevronRight, LazySvgoChevronRightStep, LazySvgoChevronRightThin, LazySvgoChevronRightThinS, LazySvgoChevronUp, LazySvgoClose, LazySvgoDesignLink, LazySvgoDiamondFilter, LazySvgoDiamond, LazySvgoEmail, LazySvgoExit, LazySvgoExpandLess, LazySvgoExpandMore, LazySvgoEyeCrossed, LazySvgoEye, LazySvgoFaq, LazySvgoFilter, LazySvgoFourColumns, LazySvgoGlobalSearch, LazySvgoGrid, LazySvgoHeartBold, LazySvgoHeartRounded, LazySvgoHeart, LazySvgoInfoFilled, LazySvgoInfoThin, LazySvgoInfo, LazySvgoInfo2, LazySvgoLabFilter, LazySvgoLabel, LazySvgoLink, LazySvgoLock, LazySvgoMenu, LazySvgoMinus, LazySvgoOneColumn, LazySvgoOutlineMail, LazySvgoPhone, LazySvgoPlus, LazySvgoRadioChecked, LazySvgoRadioUnchecked, LazySvgoReload, LazySvgoReviewRing, LazySvgoSearchNoResults, LazySvgoSearch, LazySvgoSettings, LazySvgoShipping, LazySvgoSpin, LazySvgoStar, LazySvgoStarFilled, LazySvgoStarOutlined, LazySvgoStepDiamond, LazySvgoStepRing, LazySvgoStoneShapeAsscher, LazySvgoStoneShapeBaguette, LazySvgoStoneShapeCushion, LazySvgoStoneShapeEmerald, LazySvgoStoneShapeHeart, LazySvgoStoneShapeMarquise, LazySvgoStoneShapeOval, LazySvgoStoneShapePear, LazySvgoStoneShapePrincess, LazySvgoStoneShapeRadiant, LazySvgoStoneShapeRound, LazySvgoStoneShapeTaperedBaguette, LazySvgoStoneShapeTriangle, LazySvgoStoneShapeTrillion, LazySvgoThreeDots, LazySvgoTrash, LazySvgoTruck, LazySvgoTwoColumns, LazySvgoUserCircle, LazySvgoUser } from '#components'
const lazyGlobalComponents = [
  ["SvgoAdd", LazySvgoAdd],
["SvgoAddFile", LazySvgoAddFile],
["SvgoArrowDown", LazySvgoArrowDown],
["SvgoArrowLeft", LazySvgoArrowLeft],
["SvgoArrowRight", LazySvgoArrowRight],
["SvgoArrowUp", LazySvgoArrowUp],
["SvgoBag", LazySvgoBag],
["SvgoBaselinePlus", LazySvgoBaselinePlus],
["SvgoCart", LazySvgoCart],
["SvgoChat", LazySvgoChat],
["SvgoCheck", LazySvgoCheck],
["SvgoCheckCircle", LazySvgoCheckCircle],
["SvgoCheckMark", LazySvgoCheckMark],
["SvgoChevronDown", LazySvgoChevronDown],
["SvgoChevronLeft", LazySvgoChevronLeft],
["SvgoChevronLeftThin", LazySvgoChevronLeftThin],
["SvgoChevronLeftThinS", LazySvgoChevronLeftThinS],
["SvgoChevronNext", LazySvgoChevronNext],
["SvgoChevronPrev", LazySvgoChevronPrev],
["SvgoChevronRight", LazySvgoChevronRight],
["SvgoChevronRightStep", LazySvgoChevronRightStep],
["SvgoChevronRightThin", LazySvgoChevronRightThin],
["SvgoChevronRightThinS", LazySvgoChevronRightThinS],
["SvgoChevronUp", LazySvgoChevronUp],
["SvgoClose", LazySvgoClose],
["SvgoDesignLink", LazySvgoDesignLink],
["SvgoDiamondFilter", LazySvgoDiamondFilter],
["SvgoDiamond", LazySvgoDiamond],
["SvgoEmail", LazySvgoEmail],
["SvgoExit", LazySvgoExit],
["SvgoExpandLess", LazySvgoExpandLess],
["SvgoExpandMore", LazySvgoExpandMore],
["SvgoEyeCrossed", LazySvgoEyeCrossed],
["SvgoEye", LazySvgoEye],
["SvgoFaq", LazySvgoFaq],
["SvgoFilter", LazySvgoFilter],
["SvgoFourColumns", LazySvgoFourColumns],
["SvgoGlobalSearch", LazySvgoGlobalSearch],
["SvgoGrid", LazySvgoGrid],
["SvgoHeartBold", LazySvgoHeartBold],
["SvgoHeartRounded", LazySvgoHeartRounded],
["SvgoHeart", LazySvgoHeart],
["SvgoInfoFilled", LazySvgoInfoFilled],
["SvgoInfoThin", LazySvgoInfoThin],
["SvgoInfo", LazySvgoInfo],
["SvgoInfo2", LazySvgoInfo2],
["SvgoLabFilter", LazySvgoLabFilter],
["SvgoLabel", LazySvgoLabel],
["SvgoLink", LazySvgoLink],
["SvgoLock", LazySvgoLock],
["SvgoMenu", LazySvgoMenu],
["SvgoMinus", LazySvgoMinus],
["SvgoOneColumn", LazySvgoOneColumn],
["SvgoOutlineMail", LazySvgoOutlineMail],
["SvgoPhone", LazySvgoPhone],
["SvgoPlus", LazySvgoPlus],
["SvgoRadioChecked", LazySvgoRadioChecked],
["SvgoRadioUnchecked", LazySvgoRadioUnchecked],
["SvgoReload", LazySvgoReload],
["SvgoReviewRing", LazySvgoReviewRing],
["SvgoSearchNoResults", LazySvgoSearchNoResults],
["SvgoSearch", LazySvgoSearch],
["SvgoSettings", LazySvgoSettings],
["SvgoShipping", LazySvgoShipping],
["SvgoSpin", LazySvgoSpin],
["SvgoStar", LazySvgoStar],
["SvgoStarFilled", LazySvgoStarFilled],
["SvgoStarOutlined", LazySvgoStarOutlined],
["SvgoStepDiamond", LazySvgoStepDiamond],
["SvgoStepRing", LazySvgoStepRing],
["SvgoStoneShapeAsscher", LazySvgoStoneShapeAsscher],
["SvgoStoneShapeBaguette", LazySvgoStoneShapeBaguette],
["SvgoStoneShapeCushion", LazySvgoStoneShapeCushion],
["SvgoStoneShapeEmerald", LazySvgoStoneShapeEmerald],
["SvgoStoneShapeHeart", LazySvgoStoneShapeHeart],
["SvgoStoneShapeMarquise", LazySvgoStoneShapeMarquise],
["SvgoStoneShapeOval", LazySvgoStoneShapeOval],
["SvgoStoneShapePear", LazySvgoStoneShapePear],
["SvgoStoneShapePrincess", LazySvgoStoneShapePrincess],
["SvgoStoneShapeRadiant", LazySvgoStoneShapeRadiant],
["SvgoStoneShapeRound", LazySvgoStoneShapeRound],
["SvgoStoneShapeTaperedBaguette", LazySvgoStoneShapeTaperedBaguette],
["SvgoStoneShapeTriangle", LazySvgoStoneShapeTriangle],
["SvgoStoneShapeTrillion", LazySvgoStoneShapeTrillion],
["SvgoThreeDots", LazySvgoThreeDots],
["SvgoTrash", LazySvgoTrash],
["SvgoTruck", LazySvgoTruck],
["SvgoTwoColumns", LazySvgoTwoColumns],
["SvgoUserCircle", LazySvgoUserCircle],
["SvgoUser", LazySvgoUser],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
